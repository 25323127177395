<script>
import Footer from "@/layouts/Footer.vue";
import NavBarVotacion from "@/components/NavBarVotacion.vue";
import Slider from "@/components/Slider.vue";

export default {
	props: {
		isTour: {
			required: false,
			type: Boolean,
		},
	},
	components: {
		Footer,
		NavBarVotacion,
		Slider,
	},
	mounted() {
		if (!this.isTour) {
			const reconocerButton = this.$el.querySelector("#Reconocer");
			reconocerButton.setAttribute("aria-label", "Comienza aquí");
			reconocerButton.classList.add("title-hint", "title-hint--button");
		}
	},
	methods: {
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
	},
};
</script>
<template>
	<div class="relative h-full flex flex-col justify-end items-center">
		<div class="image pt-4 -mb-6 relative z-10">
			<img
				class="block h-full object-contain m-auto"
				:src="getImage('intro_img.png')"
				alt="Trofeo"
			/>
		</div>
		<div
			class="title mb-4 pt-2 flex flex-col justify-between overlay bg-white mx-auto"
		>
			<p class="text-center text-xl px-4 py-6">
				Un <span class="b gray-dark">Creador de </span
				><span class="b green">Momentos </span>
				<span class="block"
					><span class="b red">Felices </span>es:</span
				>
			</p>
		</div>
		<div
			class="text mb-2 flex flex-col justify-between overlay bg-white mx-auto"
		>
			<slider :slidesQuantity="1" class="pt-6 px-6 flex-grow justify-between">
				<template v-slot:1>
					<p class="text-center text-xl overflow-y-auto leading-8">
						Un ejemplo a seguir, porque al
						<span class="b green">vivir </span> nuestra
						<span class="b blue">Cultura</span> construye el lugar
						donde todos queremos trabajar.
					</p>
				</template>
			</slider>
			<Nav-bar-votacion
				:active="this.$route.name"
				:isTour="isTour"
			></Nav-bar-votacion>
			<Footer class="static"></Footer>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.image {
	height: 40vh;
}
.title {
	height: 20vh;
	max-height: 120px;
}
.text {
	height: 40vh;
}

</style>
